import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../styles/recruit_interview.module.scss"

const Interview = () => {
  return (
    <Layout>
      <Seo
        title="社員インタビュー | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={`${style.mv} ${style.person01}`}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>社員インタビュー</h2>
            <h3>
              <p>やる気が自身に</p>
              <br />
              <p>クライアントの一言で司会が開けた</p>
            </h3>
            <div className={style.personProperty}>
              <p className={style.personPropertyMain}>H.O（入社２年）</p>
              <p className={style.personPropertyType}>企画・営業</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style.contentsWrap}>
        <section className={style.sectionLeft}>
          <div className={style.sectionTxt}>
            <h3>やる気だけでは、なにもできなかった</h3>
            <p>
              私は大学時代に〇〇サークルで幹事などをやっていたので、人とのコミュニケーションが得意でした。
              <br />
              老若男女問わず、色々な方とのコミュニケーション経験から営業もこなせると信じていいたのですが、いざ、入社して初めてのクライアントミーティングでは、何も話せずただ名刺交換しただけでした。
              <br />
              先輩からは「最初はそんなもんだ」と声をかけてもらいましが、結構ショックが大きく。
              <br />
              ただ、まずは前向きにやる気を出せばとうにかなると、ポジティブに考えてました。
              <br />
              テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
            </p>
          </div>
          <div className={style.sectionPic}>
            <StaticImage
              src="../../images/recruit-interview-person01-pic01.png"
              alt="商品開発"
              quality={90}
              placeholder="dominantColor"
              formats={["AUTO", "WEBP", "AVIF"]}
              layout="constrained"
            />
          </div>
        </section>

        <section className={style.sectionRight}>
          <div className={style.sectionTxt}>
            <h3>やる気だけでは、なにもできなかった</h3>
            <p>
              私は大学時代に〇〇サークルで幹事などをやっていたので、人とのコミュニケーションが得意でした。
              <br />
              老若男女問わず、色々な方とのコミュニケーション経験から営業もこなせると信じていいたのですが、いざ、入社して初めてのクライアントミーティングでは、何も話せずただ名刺交換しただけでした。
              <br />
              先輩からは「最初はそんなもんだ」と声をかけてもらいましが、結構ショックが大きく。
              <br />
              ただ、まずは前向きにやる気を出せばとうにかなると、ポジティブに考えてました。
              <br />
              テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
            </p>
          </div>
          <div className={style.sectionPic}>
            <StaticImage
              src="../../images/recruit-interview-person01-pic02.png"
              alt="商品開発"
              quality={90}
              placeholder="dominantColor"
              formats={["AUTO", "WEBP", "AVIF"]}
              layout="constrained"
            />
          </div>
        </section>

        <section className={`${style.sectionLeft} ${style.sectionOrder}`}>
          <div className={style.sectionTxt}>
            <h3>やる気だけでは、なにもできなかった</h3>
            <p>
              私は大学時代に〇〇サークルで幹事などをやっていたので、人とのコミュニケーションが得意でした。
              <br />
              老若男女問わず、色々な方とのコミュニケーション経験から営業もこなせると信じていいたのですが、いざ、入社して初めてのクライアントミーティングでは、何も話せずただ名刺交換しただけでした。
              <br />
              先輩からは「最初はそんなもんだ」と声をかけてもらいましが、結構ショックが大きく。
              <br />
              ただ、まずは前向きにやる気を出せばとうにかなると、ポジティブに考えてました。
              <br />
              テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
            </p>
          </div>
          <div className={style.sectionPic}>
            <StaticImage
              src="../../images/recruit-interview-person01-pic03.png"
              alt="商品開発"
              quality={90}
              placeholder="dominantColor"
              formats={["AUTO", "WEBP", "AVIF"]}
              layout="constrained"
            />
          </div>
        </section>

        <section className={style.oneDay}>
          <div className={style.oneDayInner}>
            <h3>社員のとある、１日</h3>
            <ul>
              <li>
                <p className={style.time}>AM 9:00</p>
                <p className={style.action}>
                  定時出社、出社後すぐにメールチェックと1日の稼働確認
                </p>
              </li>
              <li>
                <p className={style.time}>AM 10:30</p>
                <p className={style.action}>
                  企画提案の内部ミーティング。上長からの指摘やFBなどを入念に共有
                </p>
              </li>
              <li>
                <p className={style.time}>PM 12:00</p>
                <p className={style.action}>
                  同期のSとランチ。週末遊びに行く約束したので、楽しみ。午後の仕事早く終わらせて遊びの計画を立てたいと心の中で想う。
                </p>
              </li>
              <li>
                <p className={style.time}>PM 1:30</p>
                <p className={style.action}>
                  クライアントとの打ち合わせ。新しい商品開発のブレストを中心にローンチまでのスケジュールを固める
                </p>
              </li>
              <li>
                <p className={style.time}>PM 4:30</p>
                <p className={style.action}>
                  午前のミーティングの指摘・FBを資料にまとめ再編集
                </p>
              </li>
              <li>
                <p className={style.time}>PM 6:30</p>
                <p className={style.action}>
                  １日のタスク確認をして、漏れなく作業完了したかのチェックと明日業務内容把握・確認
                </p>
              </li>
              <li>
                <p className={style.time}>PM 7:00</p>
                <p className={style.action}>
                  退社。同期と週末の計画を立てるため、飲みへGO！
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section className={style.otherInterview}>
          <h3>社員インタビュー</h3>
          <div className={style.linkWrap}>
            <Link to="/recruit/interview" className={style.interview01}>
              <p className={style.interviewName}>
                やる気が自信に
                <br />
                クライアントの一言で視界が開けた
              </p>
              <p className={style.interviewProperty}>A.C（入社3年）</p>
              <p className={style.interviewType}>商品開発</p>
            </Link>
            <Link to="/recruit/interview" className={style.interview02}>
              <p className={style.interviewName}>
                やる気が自信に
                <br />
                クライアントの一言で視界が開けた
              </p>
              <p className={style.interviewProperty}>G.T（入社4年）</p>
              <p className={style.interviewType}>総務・管理</p>
            </Link>
          </div>
        </section>

        <section>
          <div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Interview
